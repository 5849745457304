import { useCallback, useEffect, useState } from 'react';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { useUserContext } from '../context/userContext';
import type {
  ReservationWithDetails,
  TopOffer,
} from '../reservationDetails/apiHelpers';
import { getReservation, getTopOffer } from '../reservationDetails/apiHelpers';
import { useError } from './useError';

interface UseReservationDetails {
  fetchReservation: () => void;
  isReservationLoading: boolean;
  reservation?: ReservationWithDetails;
  topOffer: TopOffer;
}

export const useReservationDetails = (
  reservationId?: string,
): UseReservationDetails => {
  const { isAuthenticated } = useUserContext();
  const [reservation, setReservation] = useState<ReservationWithDetails>();
  const [topOffer, setTopOffer] = useState<TopOffer>({});
  const [isReservationLoading, setIsReservationLoading] = useState(true);
  const setError = useError();

  const fetchReservation = useCallback(async () => {
    if (!reservationId) return;

    setIsReservationLoading(true);

    try {
      const reservationResponse = await getReservation(reservationId);
      if (isSuccessResponse(reservationResponse)) {
        setReservation(reservationResponse);
        if (reservationResponse.status === 'CONFIRMED') {
          const topOfferResponse = await getTopOffer(reservationId);
          if (isSuccessResponse(topOfferResponse)) {
            setTopOffer(topOfferResponse);
          }
        }
      }
    } catch (e) {
      setError(e);
    } finally {
      setIsReservationLoading(false);
    }
  }, [reservationId]);

  useEffect(() => {
    if (!isAuthenticated) return;
    void fetchReservation();
  }, [isAuthenticated]);

  return { fetchReservation, isReservationLoading, reservation, topOffer };
};
