// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubNav__subNav___JLLAo{align-items:center;display:flex;padding:0 1rem}@media only screen and (min-width: 768px){.SubNav__subNav___JLLAo{padding:0 2.5rem}}", "",{"version":3,"sources":["webpack://./src/webReservations/restaurant/SubNav.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,wBACE,kBAAA,CACA,YAAA,CACA,cAAA,CCgDE,0CDnDJ,wBAMI,gBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subNav": "SubNav__subNav___JLLAo"
};
export default ___CSS_LOADER_EXPORT___;
