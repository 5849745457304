// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationsPage__card___g0AP4{max-width:560px;background-color:var(--darkBlue);border-radius:8px;padding:32px;width:calc(100% - var(--sidePanelWidth))}.NotificationsPage__card___g0AP4>p{color:var(--white70);margin:2rem 0 0}@media only screen and (max-width: 720px){.NotificationsPage__card___g0AP4{width:100%}}.NotificationsPage__fieldset___W58qV{display:flex;flex-direction:column}.NotificationsPage__fieldset___W58qV legend{display:none}.NotificationsPage__fieldset___W58qV>div{display:flex;flex-direction:row;align-items:center;margin-bottom:1rem;column-gap:8px}.NotificationsPage__checkbox___q0uWF{align-items:center;display:flex;width:100%;text-transform:capitalize}.NotificationsPage__checkbox___q0uWF input{margin-left:auto}.NotificationsPage__hidden___uFDn0{display:none}.NotificationsPage__title___Uj7Gv{margin:0 0 1.5rem;text-align:center}.NotificationsPage__spinnerContainer___eLPNC{display:flex;align-items:center;justify-content:center}.NotificationsPage__checkBoxName___fTrji{text-transform:capitalize}", "",{"version":3,"sources":["webpack://./src/webReservations/accounts/NotificationsPage.scss","webpack://./src/shared/styles/mixins.scss"],"names":[],"mappings":"AAEA,iCACE,eAAA,CACA,gCAAA,CACA,iBAAA,CACA,YAAA,CACA,wCAAA,CAEA,mCACE,oBAAA,CACA,eAAA,CCKA,0CDdJ,iCAaI,UAAA,CAAA,CAIJ,qCACE,YAAA,CACA,qBAAA,CAEA,4CACE,YAAA,CAGF,yCACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,kBAAA,CACA,cAAA,CAIJ,qCACE,kBAAA,CACA,YAAA,CACA,UAAA,CACA,yBAAA,CAEA,2CACE,gBAAA,CAIJ,mCACE,YAAA,CAGF,kCACE,iBAAA,CACA,iBAAA,CAGF,6CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,yCACE,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "NotificationsPage__card___g0AP4",
	"fieldset": "NotificationsPage__fieldset___W58qV",
	"checkbox": "NotificationsPage__checkbox___q0uWF",
	"hidden": "NotificationsPage__hidden___uFDn0",
	"title": "NotificationsPage__title___Uj7Gv",
	"spinnerContainer": "NotificationsPage__spinnerContainer___eLPNC",
	"checkBoxName": "NotificationsPage__checkBoxName___fTrji"
};
export default ___CSS_LOADER_EXPORT___;
