import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { centsToWholeDollar } from '@utils/currency';
import typography from '~styles/typography.scss';
import { useTimer } from '../hooks/useTimer';
import { OFFERS_OUT_PATH } from '../paths';
import { formatTimeRemaining } from '../utils/formatTimeRemaining';
import type * as apiHelpers from './apiHelpers';
import {
  sendGa4OfferUpdateStartEvent,
  sendGa4OfferWithdrawStartEvent,
} from './gaHelpers';
import styles from './OfferDetails.scss';
import { UpdateOfferModal } from './UpdateOfferModal';
import { WithdrawOfferModal } from './WithdrawOfferModal';

interface OfferDetailsProps {
  offer: apiHelpers.OfferDetails;
}

export const OfferDetails = ({ offer }: OfferDetailsProps) => {
  const navigate = useNavigate();
  const timeRemaining = useTimer(offer);
  const {
    isOpen: isWithdrawOfferModalOpen,
    open: openWithdrawOfferModal,
    close: closeWithdrawOfferModal,
  } = useIsOpen();
  const {
    isOpen: isUpdateOfferModalOpen,
    open: openUpdateOfferModal,
    close: closeUpdateOfferModal,
  } = useIsOpen();

  return (
    <>
      <article className={styles.container}>
        <h2 className={cx(styles.title, typography.h4)}>Offer Details</h2>
        <div className={styles.offerDetail}>
          <span className={cx(typography.t1, styles.offerDetailName)}>
            Expires In
          </span>
          <span className={cx(typography.t1, styles.offerDetailValue)}>
            <span>{formatTimeRemaining(timeRemaining)}</span>
          </span>
        </div>
        <div className={styles.offerDetail}>
          <span className={cx(typography.t1, styles.offerDetailName)}>
            Offer Amount
          </span>
          <span className={cx(typography.t1, styles.offerDetailValue)}>
            {centsToWholeDollar(offer.price)}
          </span>
        </div>
        <div className={styles.offerDetail}>
          <span className={cx(typography.t1, styles.offerDetailName)}>
            Payment Type
          </span>
          <span className={cx(typography.t1, styles.offerDetailValue)}>
            {offer.transaction && offer.transaction.paymentMethod}
          </span>
        </div>
        <Button
          label="Update Offer"
          onClick={() => {
            sendGa4OfferUpdateStartEvent({
              date: offer.date,
              offerAmount: offer.price,
              publicName: offer.listing.publicName,
              restaurantName: offer.restaurant.name,
              time: offer.time,
            });
            openUpdateOfferModal();
          }}
          useDeprecatedBreakpoint
          variant={ButtonVariants.Primary}
        />
        <Button
          label="Withdraw Offer"
          onClick={() => {
            sendGa4OfferWithdrawStartEvent({
              date: offer.date,
              offerAmount: offer.price,
              publicName: offer.listing.publicName,
              restaurantName: offer.restaurant.name,
              time: offer.time,
            });
            openWithdrawOfferModal();
          }}
          useDeprecatedBreakpoint
          variant={ButtonVariants.Tertiary}
        />
      </article>
      {isUpdateOfferModalOpen && (
        <UpdateOfferModal offer={offer} onClose={closeUpdateOfferModal} />
      )}
      {isWithdrawOfferModalOpen && (
        <WithdrawOfferModal
          closeModal={closeWithdrawOfferModal}
          offer={offer}
          onSubmit={() => navigate(OFFERS_OUT_PATH)}
        />
      )}
    </>
  );
};
