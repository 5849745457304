import ReserveYourTable1 from '@assets/images/reserve-your-table-1.svg';
import ReserveYourTable2 from '@assets/images/reserve-your-table-2.svg';
import ReserveYourTable3 from '@assets/images/reserve-your-table-3.svg';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import typography from '~styles/typography.scss';
import styles from './UserEducationModal.scss';

interface UserEducationModalProps {
  closeModal: () => void;
}

export const UserEducationModal = ({ closeModal }: UserEducationModalProps) => (
  <Modal title="Reserve Your Table" isOpen onClose={closeModal}>
    <div className={styles.container}>
      <ol>
        <li className={typography.c2}>
          Select a time slot to reserve your table.
          <ReserveYourTable1 />
        </li>
        <li className={typography.c2}>
          From the available tables, select your favorite table.
          <ReserveYourTable2 />
        </li>
        <li className={typography.c2}>
          From the booking options, select one of the following:
          <ReserveYourTable3 />
        </li>
      </ol>
      <ModalActions>
        <Button
          label="Got it"
          onClick={closeModal}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </div>
  </Modal>
);
