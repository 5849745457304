// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthModal__form___jexs_{align-items:center;display:flex;flex-direction:column;gap:1.5rem;width:100%;max-width:28rem}.AuthModal__form___jexs_>svg{width:1.5rem}@media only screen and (min-width: 768px){.AuthModal__form___jexs_{gap:2rem}.AuthModal__form___jexs_>svg{width:2rem}}.AuthModal__form___jexs_ h1{margin:0;text-align:center}", "",{"version":3,"sources":["webpack://./src/webReservations/auth/AuthModal.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,yBACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,UAAA,CACA,eAAA,CAEA,6BACE,YAAA,CC0CA,0CDnDJ,yBAaI,QAAA,CAEA,6BACE,UAAA,CAAA,CAIJ,4BACE,QAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "AuthModal__form___jexs_"
};
export default ___CSS_LOADER_EXPORT___;
