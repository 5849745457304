import {
  type DependencyList,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { reportAppError } from '@shared/reportAppError';

export type UseAbortEffectReturn<T> =
  | ({ isPending: true } & Partial<Result<T>>)
  | ({ isPending: false } & Result<T>);

type Result<T> = { data: T; error?: never } | { data?: never; error: unknown };
type UseAbortEffectFn<T> = (signal: AbortSignal) => Promise<T>;

interface UseAbortEffectOptions<T> {
  effect: UseAbortEffectFn<T>;
  /** Invoked when the effect throws an error that is not caused by an aborted signal */
  onError: (error: unknown) => void;
}

/**
 * Similar to useEffect.
 * An AbortSignal is provided to the function.
 * The signal is aborted when dependencies change or when the component is removed.
 * If the function's returned promise rejects with an abort error, the error will be ignored.
 *
 * Use this when you have an effect that fetches data and you only want to use the most recent request.
 */
export const useAbortEffect = <T>(
  options: UseAbortEffectFn<T> | UseAbortEffectOptions<T>,
  deps: DependencyList,
): UseAbortEffectReturn<T> => {
  const { effect, onError = reportAppError } =
    typeof options === 'function' ? { effect: options } : options;
  const id = useDepsKey(deps);
  const [result, setResult] = useState<{ id: number } & Result<T>>();

  useEffect(() => {
    const abortController = new AbortController();
    void (async () => {
      try {
        const data = await effect(abortController.signal);
        if (!abortController.signal.aborted) {
          setResult({ id, data });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          setResult({ id, error });
        }
        onError(error);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [id]);

  return {
    isPending: !(result && result.id === id),
    data: result?.data,
    error: result?.error,
  } as UseAbortEffectReturn<T>;
};

/** Returns a number that increments when dependencies change */
const useDepsKey = (deps: DependencyList) => {
  const ref = useRef(0);
  useMemo(() => {
    ref.current += 1;
  }, deps);
  return ref.current;
};
