import cx from 'classnames';
import { cloneDeep, sortBy } from 'lodash-es';
import {
  type MutableRefObject,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import type { Id } from 'react-toastify';
import { Checkbox } from '@components/checkbox/Checkbox';
import { FormTooltip } from '@components/formInputs/FormTooltip';
import { Spinner } from '@components/Spinner';
import {
  successToast,
  updateToast,
  warningToast,
} from '@components/toasts/Toasts';
import { createResponseError } from '@shared/api/createResponseError';
import { ApiUnauthorizedError } from '@shared/api/errors';
import { useFeatureFlagContext } from '@shared/context/featureFlagContext';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import { isErrorResponse } from '@shared/types/apiHelpers';
import { useUserContext } from 'webReservations/context/userContext';
import type { WebReservationsFeatureFlag } from 'webReservations/featureFlags';
import typography from '~styles/typography.scss';
import { useError } from '../hooks/useError';
import {
  getSMSNotificationSettings,
  type NotificationSetting,
  updateSMSNotificationSettings,
} from './apiHelpers';
import styles from './NotificationsPage.scss';

export const LISTED_RESERVATION_PURCHASED_PREFERENCE_NAME =
  'listed reservation purchased';
export const NEW_HIGHEST_OFFER_PREFERENCE_NAME = 'new highest offer';
export const OFFER_ACCEPTED_PREFERENCE_NAME = 'offer accepted';
export const RESERVATION_REMINDER_PREFERENCE_NAME =
  'reservation reminder messages';

const NOTIFICATION_PREFERENCE_TOOLTIP_MAP: Record<string, string> = {
  [LISTED_RESERVATION_PURCHASED_PREFERENCE_NAME]:
    'Receive a notification when your listed reservation has been purchased by someone else. This message confirms that your reservation, which was up for resale, has successfully been sold.',
  [NEW_HIGHEST_OFFER_PREFERENCE_NAME]:
    'Stay updated with notifications on the latest highest offers for your listed reservations. This includes notifications for the first (initial) offer or any new highest offers thereafter.',
  [OFFER_ACCEPTED_PREFERENCE_NAME]:
    'Get notified when an offer you made on a reservation is accepted. This confirms that your offer has been successful and you now hold the reservation.',
  [RESERVATION_REMINDER_PREFERENCE_NAME]:
    'Receive reminders one hour before your reservation time.',
};

export const generateToastMessage = (
  checkBoxName: string,
  newCheckboxValue: boolean,
): ReactNode => (
  <>
    Text notifications are {newCheckboxValue ? 'enabled' : 'disabled'} for
    <strong className={styles.checkBoxName}> {checkBoxName}</strong>
  </>
);

export const NotificationsPage = () => {
  useDocumentTitle('Notifications | Peak Reservations');

  const { isEnabled } = useFeatureFlagContext<WebReservationsFeatureFlag>();
  const { clearUserState } = useUserContext();

  const [notifications, setNotifications] = useState<NotificationSetting[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const toastRef = useRef<Id | null>(null);
  const setError = useError();

  const reservationReminderFlagIsOff = !isEnabled('reservationReminderFlag');

  useEffect(() => {
    const fetch = () => {
      void (async () => {
        try {
          const response = await getSMSNotificationSettings();
          if (isErrorResponse(response)) {
            throw createResponseError(response);
          }

          setNotifications(sortBy(response.settings, 'name'));
        } catch (e) {
          if (e instanceof ApiUnauthorizedError) {
            clearUserState();
            return;
          }
          setError(e);
        }
      })();
    };
    fetch();
  }, []);

  const clearToast = (): void => {
    toastRef.current = null;
  };

  const isIdRef = (
    mysteryRef: MutableRefObject<Id | null>,
  ): mysteryRef is MutableRefObject<Id> =>
    typeof mysteryRef.current === 'string' ||
    typeof mysteryRef.current === 'number';

  const notify = (message: ReactNode) => {
    if (isIdRef(toastRef)) {
      updateToast({ message, onClose: clearToast, toastRef });
      return;
    }

    toastRef.current = successToast({ message, onClose: clearToast });
  };

  const handleCheckboxUpdate = (index: number) => {
    setIsLoading(true);

    const updatedNotifications = cloneDeep(notifications);
    const originalNotification = cloneDeep(notifications);

    const newCheckboxValue = !updatedNotifications[index].enabled;
    const notificationName = updatedNotifications[index].name;

    const toastMessage: ReactNode = generateToastMessage(
      notificationName,
      newCheckboxValue,
    );

    updatedNotifications[index].enabled = newCheckboxValue;
    setNotifications(updatedNotifications);

    void (async () => {
      const response = await updateSMSNotificationSettings({
        settings: updatedNotifications,
      });

      if (!response.ok) {
        setNotifications(originalNotification);
        warningToast({
          message: 'Error: Notification change not saved. Please try again.',
          onClose: clearToast,
        });
        setIsLoading(false);
        return;
      }
      notify(toastMessage);
      setIsLoading(false);
    })();
  };

  return (
    <div className={styles.card}>
      <h2 className={cx(styles.title, typography.h4)}>
        Text Notification Preferences
      </h2>
      <fieldset className={styles.fieldset}>
        <legend>Modify which SMS notifications you wish to receive.</legend>
        {notifications.map(({ name, enabled }, index) => {
          const checkboxStyles = cx(styles.checkbox, {
            [styles.hidden]:
              reservationReminderFlagIsOff &&
              name === RESERVATION_REMINDER_PREFERENCE_NAME,
          });

          return (
            <div key={name}>
              {NOTIFICATION_PREFERENCE_TOOLTIP_MAP[name] && (
                <FormTooltip
                  label={`${name} notification preference`}
                  text={NOTIFICATION_PREFERENCE_TOOLTIP_MAP[name]}
                />
              )}

              <Checkbox
                className={checkboxStyles}
                checked={enabled}
                disabled={isLoading}
                label={name}
                onChange={() => {
                  handleCheckboxUpdate(index);
                }}
              />
            </div>
          );
        })}
      </fieldset>
      <p className={typography.t2}>
        Note: Notifications may sometimes be scheduled before a preference is
        updated. Disabling a preference will prevent all future notifications
        but will not affect existing scheduled notifications.
      </p>
      {isLoading && (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}
    </div>
  );
};
