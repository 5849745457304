import { Navigate, Outlet } from 'react-router-dom';
import { DeprecatedLayout } from 'webReservations/layout/DeprecatedLayout';
import { useOfferCheckoutContext } from '../context/offerCheckoutContext';
import { useUserContext } from '../context/userContext';
import { LANDING_PATH } from '../paths';
import styles from './OfferCheckoutLayout.scss';

export const OfferCheckoutLayout = () => {
  const { isAuthenticated } = useUserContext();
  const { offerCheckout } = useOfferCheckoutContext();

  if (!isAuthenticated || !offerCheckout) {
    return <Navigate to={LANDING_PATH} />;
  }

  return (
    <DeprecatedLayout className={styles.layout}>
      <Outlet />;
    </DeprecatedLayout>
  );
};
