// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConnectBankAccountModal__container___mwBlI p{color:var(--white70);margin:0 auto 1em;max-width:30em;text-wrap:pretty}@media only screen and (min-width: 768px){.ConnectBankAccountModal__container___mwBlI p{text-align:center}}.ConnectBankAccountModal__container___mwBlI li{text-align:center}.ConnectBankAccountModal__container___mwBlI li a{color:var(--white)}", "",{"version":3,"sources":["webpack://./src/webReservations/accounts/ConnectBankAccountModal.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAGE,8CACE,oBAAA,CACA,iBAAA,CACA,cAAA,CACA,gBAAA,CC8CA,0CDlDF,8CAOI,iBAAA,CAAA,CAIJ,+CACE,iBAAA,CAEA,iDACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ConnectBankAccountModal__container___mwBlI"
};
export default ___CSS_LOADER_EXPORT___;
