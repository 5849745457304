// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WithdrawOfferModal__container___PvPQQ p{color:var(--white70);margin:0 auto;max-width:30em;text-wrap:pretty}@media only screen and (min-width: 768px){.WithdrawOfferModal__container___PvPQQ p{text-align:center}}.WithdrawOfferModal__container___PvPQQ p+p{margin-top:1.5rem}.WithdrawOfferModal__container___PvPQQ .WithdrawOfferModal__error___ZFe0l{color:var(--pink);margin-top:1rem}", "",{"version":3,"sources":["webpack://./src/webReservations/offers/WithdrawOfferModal.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAGE,yCACE,oBAAA,CACA,aAAA,CACA,cAAA,CACA,gBAAA,CC8CA,0CDlDF,yCAOI,iBAAA,CAAA,CAIJ,2CACE,iBAAA,CAGF,0EACE,iBAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "WithdrawOfferModal__container___PvPQQ",
	"error": "WithdrawOfferModal__error___ZFe0l"
};
export default ___CSS_LOADER_EXPORT___;
