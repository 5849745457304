// root level standalone paths
export const LANDING_PATH = '/';
export const ABOUT_US_PATH = '/about-us';
export const FAQ_PATH = '/faq';
export const PRIVACY_POLICY_PATH = '/privacy-policy';
export const RESTAURANT_DEMO_PATH = '/demos';
export const RESTAURANTS_PATH = '/dir';
export const TERMS_OF_SERVICE_PATH = '/terms-of-service';
export const REDIRECT_PATH = 'redirect';

// account paths
export const ACCOUNT_ROOT_PATH = '/account';
export const ACCOUNT_NOTIFICATIONS_PATH = '/account/notifications';
export const ACCOUNT_BALANCE_PATH = '/account/balance';
export const ACCOUNT_PAYMENT_METHODS_PATH = '/account/payment-methods';

// plaid paths
export const PLAID_ROOT_PATH = 'plaid';
export const PLAID_AUTH_PATH = '/plaid/auth';
export const PLAID_AUTH_TERMINAL_PATH = 'auth';

// checkout paths
export const CHECKOUT_ROOT_PATH = '/checkout';
export const CHECKOUT_CONFIRMATION_TERMINAL_PATH = 'confirmation';

// email verification paths
export const EMAIL_VERIFICATION_FAIL_PATH = '/email-verification/fail';
export const EMAIL_VERIFICATION_SUCCESS_PATH = '/email-verification/success';

// offers paths
export const OFFERS_ROOT_PATH = '/offers';
export const OFFERS_IN_PATH = '/offers/in';
export const OFFERS_OUT_PATH = '/offers/out';

// offer checkout paths
export const OFFER_CHECKOUT_ROOT_PATH = '/offer-checkout';
export const OFFER_CHECKOUT_CONFIRMATION_TERMINAL_PATH = 'offer-confirmation';

// offer details path (not using the same layout as above)
export const OFFER_OUT_DETAILS_PATH = '/offers/:offerId';

// reservations paths
export const RESERVATIONS_ROOT_PATH = '/reservations';
export const RESERVATIONS_CONFIRMED_PATH = '/reservations/confirmed';
export const RESERVATIONS_INACTIVE_PATH = '/reservations/inactive';
export const RESERVATION_DETAILS_PATH = '/reservations/:reservationId';

// hiring path
export const HIRING_PATH = 'https://apply.workable.com/peak-reservations/';
