import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import typography from '~styles/typography.scss';
import styles from './SubNavLink.scss';
import { UserEducationModal } from './UserEducationModal';

export const HelpSubNavButton = () => {
  const { isOpen, open, close } = useIsOpen();

  return (
    <>
      <button
        type="button"
        onClick={open}
        aria-label="Help"
        className={cx(styles.button, styles.helpButton)}
      >
        <Icon name="questionMark" />
        <span className={typography.t1}>Help</span>
      </button>
      {isOpen && <UserEducationModal closeModal={close} />}
    </>
  );
};
