// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserEducationModal__container___wqsbW{padding:16px;display:flex;flex-direction:column}@media only screen and (min-width: 768px){.UserEducationModal__container___wqsbW{width:560px;padding:0 48px 48px}}.UserEducationModal__container___wqsbW ol{padding-inline-start:0;margin:0}.UserEducationModal__container___wqsbW ol li:not(:last-child){margin-bottom:16px}.UserEducationModal__container___wqsbW ol li:not(:last-child) svg{margin-top:24px}", "",{"version":3,"sources":["webpack://./src/webReservations/restaurant/UserEducationModal.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,uCACE,YAAA,CACA,YAAA,CACA,qBAAA,CCgDE,0CDnDJ,uCAMI,WAAA,CACA,mBAAA,CAAA,CAGF,0CACE,sBAAA,CACA,QAAA,CAEA,8DACE,kBAAA,CAEA,kEACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UserEducationModal__container___wqsbW"
};
export default ___CSS_LOADER_EXPORT___;
