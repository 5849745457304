import type { ReactNode } from 'react';
import { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { todayInTimezone } from '@utils/date';
import type { Availability, RestaurantDetails } from '../restaurant/apiHelpers';
import { getAvailabilities, getClosureForDate } from '../restaurant/apiHelpers';
import {
  calculateFilteredData,
  getAvailableTimes,
} from './availabilityDerivedState';
import {
  availabilityReducer,
  clearSelectedAvailability,
  incrementShowEarlierOffset,
  incrementShowLaterOffset,
  initialAvailabilityState,
  setAvailabilities,
  setSelectedAvailability,
  setSelectedDate,
  setSelectedFloorPlanListingIds,
  setSelectedGuestCount,
  setSelectedTime,
} from './availabilityReducer';
import { useRestaurantContext } from './restaurantContext';

export interface TSelectedAvailability {
  isBiddable: boolean;
  isBuyable: boolean;
  listingId: string;
  purchasePrice: number;
  publicName: string;
  time: string;
}

export interface AvailabilityContextState {
  availableTimes: string[];
  clearSelectedAvailability: () => void;
  expandedAvailabilityListingIds: string[];
  filteredAvailabilities: Availability[];
  handleShowEarlier: () => void;
  handleShowLater: () => void;
  hasAvailabilitiesOnGivenDay: boolean;
  hasEarlier: boolean;
  hasLater: boolean;
  hasUnsupportedGuestCount: boolean;
  isClosedToday: boolean;
  isSelectedDateToday: boolean;
  selectedAvailability: TSelectedAvailability | null;
  selectedDate: string;
  selectedFloorPlanListingIds: string[];
  selectedGuestCount: number;
  selectedTime: string;
  setSelectedAvailability: (availability: TSelectedAvailability) => void;
  setSelectedDate: (date: string) => void;
  setSelectedFloorPlanListingIds: (ids: string[]) => void;
  setSelectedGuestCount: (guestCount: number) => void;
  setSelectedTime: (time: string) => void;
}

const AvailabilityContext = createContext<AvailabilityContextState | null>(
  null,
);
AvailabilityContext.displayName = 'AvailabilityContext';

export const useAvailabilityContext = () =>
  useDefinedContext(AvailabilityContext);

interface AvailabilityContextProviderProps {
  children: ReactNode;
}

const useIsRestaurantClosedToday = (restaurantDetails: RestaurantDetails) => {
  const [isClosedToday, setIsClosedToday] = useState(true);

  useEffect(() => {
    const fetchIsOpenToday = async (restaurantId: string, date: string) => {
      const response = await getClosureForDate(restaurantId, date);

      if (isSuccessResponse(response)) {
        setIsClosedToday(response.length > 0);
      } else {
        setIsClosedToday(false);
      }
    };

    if (restaurantDetails.id) {
      void fetchIsOpenToday(
        restaurantDetails.id,
        todayInTimezone(restaurantDetails.timezone),
      );
    }
  }, [restaurantDetails.id]);

  return { isClosedToday };
};

export const AvailabilityContextProvider = ({
  children,
}: AvailabilityContextProviderProps) => {
  const { restaurantDetails } = useRestaurantContext();
  const { isClosedToday } = useIsRestaurantClosedToday(restaurantDetails);
  const [state, dispatch] = useReducer(
    availabilityReducer,
    initialAvailabilityState(),
  );
  const {
    availabilities,
    selectedGuestCount,
    selectedTime,
    selectedDate,
    selectedFloorPlanListingIds,
    selectedAvailability,
  } = state;

  useEffect(() => {
    if (restaurantDetails.id) {
      const fetchAvailability = async (): Promise<void> => {
        const allAvailabilities = await getAvailabilities(
          restaurantDetails.id,
          selectedDate,
        );
        dispatch(setAvailabilities(allAvailabilities));
      };
      void fetchAvailability();
    }
  }, [selectedDate, restaurantDetails]);

  const handleShowEarlier = () => {
    dispatch(incrementShowEarlierOffset());
  };

  const handleShowLater = () => {
    dispatch(incrementShowLaterOffset());
  };

  const {
    filteredAvailabilities,
    hasEarlier,
    hasLater,
    expandedAvailabilityListingIds,
    hasUnsupportedGuestCount,
  } = calculateFilteredData(state);

  const availableTimes = getAvailableTimes(state);

  const isSelectedDateToday =
    todayInTimezone(restaurantDetails.timezone) === selectedDate;

  const value = useMemo<AvailabilityContextState>(
    () => ({
      availableTimes,
      clearSelectedAvailability: () => dispatch(clearSelectedAvailability()),
      filteredAvailabilities,
      handleShowEarlier,
      handleShowLater,
      hasAvailabilitiesOnGivenDay: !!availabilities.length,
      hasEarlier,
      hasLater,
      hasUnsupportedGuestCount,
      isClosedToday,
      selectedAvailability,
      selectedDate,
      selectedGuestCount,
      selectedFloorPlanListingIds,
      selectedTime,
      setSelectedAvailability: (availability: TSelectedAvailability) =>
        dispatch(setSelectedAvailability(availability)),
      setSelectedDate: (date: string) => dispatch(setSelectedDate(date)),
      setSelectedFloorPlanListingIds: (floorPlanListingIds: string[]) =>
        dispatch(setSelectedFloorPlanListingIds(floorPlanListingIds)),
      setSelectedGuestCount: (guestCount: number) =>
        dispatch(setSelectedGuestCount(guestCount)),
      setSelectedTime: (time: string) => dispatch(setSelectedTime(time)),
      expandedAvailabilityListingIds,
      isSelectedDateToday,
    }),
    [state],
  );

  return (
    <AvailabilityContext.Provider value={value}>
      {children}
    </AvailabilityContext.Provider>
  );
};
