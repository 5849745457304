import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router-dom';
import { StripeElements } from '@components/StripeElements';
import { MOBILE } from '@shared/styles/breakpoints';
import { useUserContext } from '../context/userContext';
import { Unauthenticated } from '../components/Unauthenticated';
import { DeprecatedLayout } from '../layout/DeprecatedLayout';
import styles from './AccountsLayout.scss';
import { AccountsSidebar } from './AccountsSidebar';

export const AccountsLayout = () => {
  const { isAuthenticated } = useUserContext();
  const isTablet = useMediaQuery({ minWidth: MOBILE });

  return (
    <StripeElements>
      <DeprecatedLayout>
        {isAuthenticated ? (
          <main>
            {isTablet && <AccountsSidebar />}
            <div className={styles.content}>
              <Outlet />
            </div>
          </main>
        ) : (
          <Unauthenticated
            className={styles.unauthenticated}
            subtitle="Login to manage your notifications, balance, and more."
            title="Manage your settings"
          />
        )}
      </DeprecatedLayout>
    </StripeElements>
  );
};
