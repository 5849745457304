import * as icons from '@assets/icons';
import * as floorPlanIcons from '@assets/icons/floorPlan';
import * as serviceStatusIcons from '@assets/icons/serviceStatus';

export const IconComponents = {
  // General Icons
  accountCircleOff: icons.AccountCircleOff,
  alertCircle: icons.AlertCircle,
  alertOctagon: icons.AlertOctagon,
  alertTriangle: icons.AlertTriangle,
  amex: icons.AmEx,
  arrowDownCircle: icons.ArrowDownCircle,
  arrowLeft: icons.ArrowLeft,
  browser: icons.Browser,
  calendar: icons.Calendar,
  checkMark: icons.CheckMark,
  checkMarkCircle: icons.CheckMarkCircle,
  chevron: icons.Chevron,
  clock: icons.Clock,
  close: icons.Close,
  copy: icons.Copy,
  couple: icons.Couple,
  creditCard: icons.CreditCard,
  crown: icons.Crown,
  cutlery: icons.Cutlery,
  defaultCardLogo: icons.DefaultCardLogo,
  email: icons.Email,
  filter: icons.Filter,
  gear: icons.Gear,
  hourglass: icons.Hourglass,
  infoCircle: icons.InfoCircle,
  link: icons.Link,
  list: icons.List,
  logo: icons.Logo,
  locationArrow: icons.LocationArrow,
  mastercard: icons.Mastercard,
  minus: icons.Minus,
  note: icons.Note,
  options: icons.Options,
  pencil: icons.Pencil,
  phone: icons.Phone,
  plus: icons.Plus,
  printer: icons.Printer,
  questionMark: icons.QuestionMark,
  reset: icons.Reset,
  roomService: icons.RoomService,
  smallCheck: icons.SmallCheck,
  splitMerge: icons.SplitMerge,
  table: icons.Table,
  tent: icons.Tent,
  timer: icons.Timer,
  trash: icons.Trash,
  turnTime: icons.TurnTime,
  user: icons.User,
  visa: icons.Visa,
  walkIn: icons.WalkIn,
  walkInNoBackground: icons.WalkInNoBackground,
  wrench: icons.Wrench,
  xCircle: icons.XCircle,
  zoomIn: icons.ZoomIn,
  zoomOut: icons.ZoomOut,
  // Bar Seating
  barSeat: floorPlanIcons.BarSeat,
  barSeatError: floorPlanIcons.BarSeatError,
  barSeatSelected: floorPlanIcons.BarSeatSelected,
  barSeatSmall: floorPlanIcons.BarSeatSmall,
  barSeatSmallError: floorPlanIcons.BarSeatSmallError,
  barSeatSmallSelected: floorPlanIcons.BarSeatSmallSelected,
  // Cabana
  cabanaTable: floorPlanIcons.CabanaTable,
  cabanaTableError: floorPlanIcons.CabanaTableError,
  cabanaTableSelected: floorPlanIcons.CabanaTableSelected,
  // Merged Tables
  mergedTable: floorPlanIcons.MergedTable,
  mergedTableError: floorPlanIcons.MergedTableError,
  mergedTableSelected: floorPlanIcons.MergedTableSelected,
  // Oval Tables
  ovlTable6RndChairs: floorPlanIcons.OvlTable6RndChairs,
  ovlTable6RndChairsError: floorPlanIcons.OvlTable6RndChairsError,
  ovlTable6RndChairsSelected: floorPlanIcons.OvlTable6RndChairsSelected,
  // Rectangular Tables
  recTable2Benches: floorPlanIcons.RecTable2Benches,
  recTable2BenchesError: floorPlanIcons.RecTable2BenchesError,
  recTable2BenchesSelected: floorPlanIcons.RecTable2BenchesSelected,
  recTable2Couches: floorPlanIcons.RecTable2Couches,
  recTable2CouchesError: floorPlanIcons.RecTable2CouchesError,
  recTable2CouchesSelected: floorPlanIcons.RecTable2CouchesSelected,
  recTable2LoveSeats: floorPlanIcons.RecTable2LoveSeats,
  recTable2LoveSeatsError: floorPlanIcons.RecTable2LoveSeatsError,
  recTable2LoveSeatsSelected: floorPlanIcons.RecTable2LoveSeatsSelected,
  recTable2RndChairsOneSide: floorPlanIcons.RecTable2RndChairsOneSide,
  recTable2RndChairsOneSideError: floorPlanIcons.RecTable2RndChairsOneSideError,
  recTable2RndChairsOneSideSelected:
    floorPlanIcons.RecTable2RndChairsOneSideSelected,
  recTable2SqrChairsAndBench: floorPlanIcons.RecTable2SqrChairsAndBench,
  recTable2SqrChairsAndBenchError:
    floorPlanIcons.RecTable2SqrChairsAndBenchError,
  recTable2SqrChairsAndBenchSelected:
    floorPlanIcons.RecTable2SqrChairsAndBenchSelected,
  recTable3SqrChairs50PctWrapBench:
    floorPlanIcons.RecTable3SqrChairs50PctWrapBench,
  recTable3SqrChairs50PctWrapBenchError:
    floorPlanIcons.RecTable3SqrChairs50PctWrapBenchError,
  recTable3SqrChairs50PctWrapBenchSelected:
    floorPlanIcons.RecTable3SqrChairs50PctWrapBenchSelected,
  recTable3SqrChairsAndBench: floorPlanIcons.RecTable3SqrChairsAndBench,
  recTable3SqrChairsAndBenchError:
    floorPlanIcons.RecTable3SqrChairsAndBenchError,
  recTable3SqrChairsAndBenchSelected:
    floorPlanIcons.RecTable3SqrChairsAndBenchSelected,
  recTable4SqrChairs: floorPlanIcons.RecTable4SqrChairs,
  recTable4SqrChairsError: floorPlanIcons.RecTable4SqrChairsError,
  recTable4SqrChairsSelected: floorPlanIcons.RecTable4SqrChairsSelected,
  recTable5SqrChairs50PctWrapBench:
    floorPlanIcons.RecTable5SqrChairs50PctWrapBench,
  recTable5SqrChairs50PctWrapBenchError:
    floorPlanIcons.RecTable5SqrChairs50PctWrapBenchError,
  recTable5SqrChairs50PctWrapBenchSelected:
    floorPlanIcons.RecTable5SqrChairs50PctWrapBenchSelected,
  recTable6RndChairs: floorPlanIcons.RecTable6RndChairs,
  recTable6RndChairsError: floorPlanIcons.RecTable6RndChairsError,
  recTable6RndChairsSelected: floorPlanIcons.RecTable6RndChairsSelected,
  recTable6SqrChairs: floorPlanIcons.RecTable6SqrChairs,
  recTable6SqrChairsError: floorPlanIcons.RecTable6SqrChairsError,
  recTable6SqrChairsSelected: floorPlanIcons.RecTable6SqrChairsSelected,
  // Round Tables
  rndTable2RndChairs: floorPlanIcons.RndTable2RndChairs,
  rndTable2RndChairsError: floorPlanIcons.RndTable2RndChairsError,
  rndTable2RndChairsSelected: floorPlanIcons.RndTable2RndChairsSelected,
  rndTable4RndChairs: floorPlanIcons.RndTable4RndChairs,
  rndTable4RndChairsError: floorPlanIcons.RndTable4RndChairsError,
  rndTable4RndChairsSelected: floorPlanIcons.RndTable4RndChairsSelected,
  rndTable4SqrChairs: floorPlanIcons.RndTable4SqrChairs,
  rndTable4SqrChairsError: floorPlanIcons.RndTable4SqrChairsError,
  rndTable4SqrChairsSelected: floorPlanIcons.RndTable4SqrChairsSelected,
  rndTable50PctWrapBench: floorPlanIcons.RndTable50PctWrapBench,
  rndTable50PctWrapBenchError: floorPlanIcons.RndTable50PctWrapBenchError,
  rndTable50PctWrapBenchSelected: floorPlanIcons.RndTable50PctWrapBenchSelected,
  rndTable50PctWrapCornerBench: floorPlanIcons.RndTable50PctWrapCornerBench,
  rndTable50PctWrapCornerBenchError:
    floorPlanIcons.RndTable50PctWrapCornerBenchError,
  rndTable50PctWrapCornerBenchSelected:
    floorPlanIcons.RndTable50PctWrapCornerBenchSelected,
  rndTable8RndChairs: floorPlanIcons.RndTable8RndChairs,
  rndTable8RndChairsError: floorPlanIcons.RndTable8RndChairsError,
  rndTable8RndChairsSelected: floorPlanIcons.RndTable8RndChairsSelected,
  // Square Tables
  sqrTable1RndChair1SqrChair: floorPlanIcons.SqrTable1RndChair1SqrChair,
  sqrTable1RndChair1SqrChairError:
    floorPlanIcons.SqrTable1RndChair1SqrChairError,
  sqrTable1RndChair1SqrChairSelected:
    floorPlanIcons.SqrTable1RndChair1SqrChairSelected,
  sqrTable2RndChairs: floorPlanIcons.SqrTable2RndChairs,
  sqrTable2RndChairsError: floorPlanIcons.SqrTable2RndChairsError,
  sqrTable2RndChairsSelected: floorPlanIcons.SqrTable2RndChairsSelected,
  sqrTable2SqrChairs: floorPlanIcons.SqrTable2SqrChairs,
  sqrTable2SqrChairsError: floorPlanIcons.SqrTable2SqrChairsError,
  sqrTable2SqrChairsSelected: floorPlanIcons.SqrTable2SqrChairsSelected,
  sqrTable4RndChairs: floorPlanIcons.SqrTable4RndChairs,
  sqrTable4RndChairsError: floorPlanIcons.SqrTable4RndChairsError,
  sqrTable4RndChairsSelected: floorPlanIcons.SqrTable4RndChairsSelected,
  sqrTable4SqrChairs: floorPlanIcons.SqrTable4SqrChairs,
  sqrTable4SqrChairsError: floorPlanIcons.SqrTable4SqrChairsError,
  sqrTable4SqrChairsSelected: floorPlanIcons.SqrTable4SqrChairsSelected,
  sqrTable50PctWrapBench: floorPlanIcons.SqrTable50PctWrapBench,
  sqrTable50PctWrapBenchError: floorPlanIcons.SqrTable50PctWrapBenchError,
  sqrTable50PctWrapBenchSelected: floorPlanIcons.SqrTable50PctWrapBenchSelected,
  sqrTable75PctWrapBench: floorPlanIcons.SqrTable75PctWrapBench,
  sqrTable75PctWrapBenchError: floorPlanIcons.SqrTable75PctWrapBenchError,
  sqrTable75PctWrapBenchSelected: floorPlanIcons.SqrTable75PctWrapBenchSelected,
  sqrTable75PctWrapCornerBench: floorPlanIcons.SqrTable75PctWrapCornerBench,
  sqrTable75PctWrapCornerBenchError:
    floorPlanIcons.SqrTable75PctWrapCornerBenchError,
  sqrTable75PctWrapCornerBenchSelected:
    floorPlanIcons.SqrTable75PctWrapCornerBenchSelected,
  twoSqrTable2LoveSeats3ArmChairs:
    floorPlanIcons.TwoSqrTable2LoveSeats3ArmChairs,
  twoSqrTable2LoveSeats3ArmChairsError:
    floorPlanIcons.TwoSqrTable2LoveSeats3ArmChairsError,
  twoSqrTable2LoveSeats3ArmChairsSelected:
    floorPlanIcons.TwoSqrTable2LoveSeats3ArmChairsSelected,
  // Service Status
  appetizer: serviceStatusIcons.Appetizer,
  arrived: serviceStatusIcons.Arrived,
  booked: serviceStatusIcons.Booked,
  bottleService: serviceStatusIcons.BottleService,
  checkDropped: serviceStatusIcons.CheckDropped,
  cleared: serviceStatusIcons.Cleared,
  confirmed: serviceStatusIcons.Confirmed,
  dessert: serviceStatusIcons.Dessert,
  drinks: serviceStatusIcons.Drinks,
  entree: serviceStatusIcons.Entree,
  leftMessage: serviceStatusIcons.LeftMessage,
  noAnswer: serviceStatusIcons.NoAnswer,
  onTheWay: serviceStatusIcons.OnTheWay,
  paid: serviceStatusIcons.Paid,
  partiallyArrived: serviceStatusIcons.PartiallyArrived,
  partiallySeated: serviceStatusIcons.PartiallySeated,
  postMealDrink: serviceStatusIcons.PostMealDrink,
  runningLate: serviceStatusIcons.RunningLate,
  seated: serviceStatusIcons.Seated,
  tableKnock: serviceStatusIcons.TableKnock,
  tableReady: serviceStatusIcons.TableReady,
  unconfirmed: serviceStatusIcons.Unconfirmed,
  wrongNumber: serviceStatusIcons.WrongNumber,
} as const;
