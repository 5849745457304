// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestCancelConfirmationModal__content___b5wPG{color:var(--white70);display:flex;flex-direction:column;gap:1rem}.GuestCancelConfirmationModal__content___b5wPG p{margin:0;max-width:30em}.GuestCancelConfirmationModal__content___b5wPG strong{color:var(--white);font-weight:600;letter-spacing:.01em}.GuestCancelConfirmationModal__content___b5wPG dl{display:flex;flex-direction:column;gap:.5rem;width:fit-content}@media only screen and (min-width: 390px){.GuestCancelConfirmationModal__content___b5wPG dl{align-self:center}}.GuestCancelConfirmationModal__content___b5wPG dl>div{display:grid;gap:.5rem;grid-template-columns:1fr 1fr}.GuestCancelConfirmationModal__content___b5wPG dl>div dd{margin-left:0}.GuestCancelConfirmationModal__content___b5wPG dl>div dt{white-space:nowrap}.GuestCancelConfirmationModal__content___b5wPG a{color:inherit;font-size:1rem;font-weight:400;line-height:1.5rem;text-decoration:underline;white-space:nowrap}.GuestCancelConfirmationModal__content___b5wPG .GuestCancelConfirmationModal__modalActions___mMxD8{margin-top:2rem}", "",{"version":3,"sources":["webpack://./src/webReservations/reservationDetails/GuestCancelConfirmationModal.scss","webpack://./src/shared/styles/iPadBreakpoints.scss"],"names":[],"mappings":"AAEA,+CACE,oBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,iDACE,QAAA,CACA,cAAA,CAGF,sDACE,kBAAA,CACA,eAAA,CACA,oBAAA,CAGF,kDACE,YAAA,CACA,qBAAA,CACA,SAAA,CACA,iBAAA,CCsBA,0CD1BF,kDAOI,iBAAA,CAAA,CAGF,sDACE,YAAA,CACA,SAAA,CACA,6BAAA,CAEA,yDACE,aAAA,CAGF,yDACE,kBAAA,CAKN,iDACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,yBAAA,CACA,kBAAA,CAGF,mGACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "GuestCancelConfirmationModal__content___b5wPG",
	"modalActions": "GuestCancelConfirmationModal__modalActions___mMxD8"
};
export default ___CSS_LOADER_EXPORT___;
