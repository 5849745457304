import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import { ButtonVariants } from '@components/button/Button';
import { Divider } from '@components/divider/Divider';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import { useDocumentTitle } from '@shared/hooks/setDocumentTitle';
import { ReservationAttributesGrid } from 'webReservations/reservations/ReservationAttributesGrid';
import typography from '~styles/typography.scss';
import { useRestaurantContext } from '../context/restaurantContext';
import { OFFERS_ROOT_PATH } from '../paths';
import { Address } from '../reservations/Address';
import styles from './OfferCheckoutConfirmationPage.scss';
import { OfferCheckoutReceipt } from './OfferCheckoutReceipt';

export const OfferCheckoutConfirmationPage = () => {
  const {
    state: { offerAmount, fee, tax, total, date, guestCount, publicName, time },
  } = useLocation();

  const { restaurantDetails } = useRestaurantContext();
  useDocumentTitle(`Checkout Offer Confirmation | Peak Reservations`);

  return (
    <main className={styles.main}>
      <div className={styles.header}>
        <h1 className={typography.d3}>Your offer was made!</h1>
        <h3 className={typography.c2}>
          You will receive a text or email if your offer is accepted.
        </h3>
      </div>
      <div className={styles.reservation}>
        <p className={cx(typography.d4, styles.restaurantName)}>
          {restaurantDetails.name}
        </p>
        <Address value={restaurantDetails.address} />
        <ReservationAttributesGrid
          containerClassName={styles.reservationAttributesContainer}
          date={date}
          guestCount={guestCount}
          publicName={publicName}
          time={time}
        />
        <Divider />
        <OfferCheckoutReceipt
          date={date}
          fee={fee}
          price={offerAmount}
          tax={tax}
          total={total}
        />
      </div>
      <div className={styles.actions}>
        <LinkStyledAsButton
          to={OFFERS_ROOT_PATH}
          variant={ButtonVariants.Tertiary}
          label="View Your Offers"
        />
      </div>
    </main>
  );
};
